@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$lightBluecolor: #f5f9ff;
$color: #f8f8ff;
$color2: #f9fafb;
$color3: #24272d;
$Bluecolor: #0461f0;
$Bluecolor2: #475367;
$Bluecolor3: #0d3a8f;
$Bluecolor4: #004291;
$Bluecolor5: #3262c6;
$Bluecolor6: #0b4870;
$greycolor: #545a79;
$greycolor2: #757297;
$greycolor3: #66707f;
$greycolor4: #eaeafb;
$greycolor5: #e4eefc;
$purplecolor: #707fe0;
$orangecolor: #ffbe55;
$fontFamily: "Bricolage Grotesque", sans-serif;
$fontFamily2: "Helvetica", sans-serif;

//common css
.height-auto {
  height: auto;
}

.commonhead {
  background-color: $lightBluecolor;
  border-radius: 12px;
  padding: 8px 10px;
  text-align: center;
  display: inline;

  h5 {
    font-family: $fontFamily2;
    display: inline;
    color: $Bluecolor;
    font-size: 16px;
    font-weight: 700;
  }
}

.sectionMargin {
  margin-top: 100px;
}

.heading {
  font-size: 48px;
  font-weight: 700;
  font-family: $fontFamily;
  margin: 10px 0px;
}

.button1 {
  border-radius: 8px;
  background: linear-gradient(96.71deg, #5656f2 0%, #3333dc 101.64%);
  box-shadow: 0px 4px 10px 0px #e7e7fb;
  border: none;
  font-size: 16px;
  font-weight: 700;
  font-family: $fontFamily2;
  padding: 10px 20px;
  margin: 10px 0px;
  color: #fff;

  &:hover {
    box-shadow: none;
    background: #fff;
    border: 1px solid #3333dc;
    color: $Bluecolor3;
  }
}

.applyBtns {
  box-shadow: 0px 4.63px 18.5px 0px #4a3aff47;
  background-color: #4a3aff;
  font-size: 20px;
  display: block;
  width: 100%;
  cursor: pointer;
  font-family: $fontFamily;
  font-weight: 500;
  color: #fff;
  border: none;
  text-decoration: none;
  border-radius: 80px;
  padding: 30px 35px;
  text-align: center;

  &:hover {
    background-color: #fff;
    color: #4a3aff;
  }
}
.applyBtns2 {
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0px 4.63px 18.5px 0px #4a3aff47;
  background-color: #1500ff;
  // background-color: #4A3AFF;
  font-size: 18px;
  display: block;
  width: fit-content;
  font-family: $fontFamily;
  font-weight: 500;
  color: #fff;
  border: none;
  text-decoration: none;
  border-radius: 80px;
  padding: 20px 55px;
  text-align: center;

  &:hover {
    background-color: #fff;
    color: #4a3aff;
  }
}

// custom css

.banner-section {
  position: relative;
  z-index: 1;
  width: 100%;

  .bannerimg {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../Assets/bannerImg1.png");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  .bannerimg2 {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../Assets/bannerImg2.png");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  .bannerimg3 {
    position: absolute;
    top: 0;
    left: 0;
    // background-image: url('../Assets/bannerImg2.png');
    background: linear-gradient(to Bottom, #0d3a8f 77%, #fff 100%);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  .bannerShapebottom {
    position: absolute;
    right: 0;
    top: 1px;
    height: 100%;
    width: 100%;
    z-index: 0;

    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .text {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 15% 0% 20% 0%;
    // text-align: center;

    h1 {
      font-size: 65px;
      font-weight: 700;
      font-family: $fontFamily;
      color: #fff;
    }

    .imgs {
      position: relative;
      z-index: 1;
      width: 100%;

      .bannerSideimg1 {
        position: absolute;

        background-image: url("../Assets/bannerSideimg1.svg");
        width: 47%;
        height: 80px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: -5%;
        right: 2%;
        z-index: 0;
      }

      .bannerSideimg2 {
        position: absolute;

        background-image: url("../Assets/bannerSideimg2.svg");
        width: 47%;
        height: 80px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: 50%;
        left: -10%;
        z-index: 0;
      }

      .bannerSideimg3 {
        position: absolute;

        background-image: url("../Assets/bannerSideimg3.svg");
        width: 50%;
        height: 80px;
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -5%;
        left: 10%;
        z-index: 0;
      }

      .bannerSideimg4 {
        position: absolute;

        background-image: url("../Assets/bannerSideimg4.svg");
        width: 50%;
        height: 103px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: 50%;
        right: 0%;
        z-index: 0;
      }
    }
  }
}

.about-section {
  .about-content {
    h6 {
      color: $Bluecolor2;
      font-size: 18px;
      font-weight: 400;
      margin: 15px 0px;
      font-family: $fontFamily2;
    }
  }
}

.LoanProduct-section {
  .outerloancard {
    padding: 20px 10px;
    margin-top: 15px;

    .loanCard {
      padding: 24px 60px 24px 24px;
      border-radius: 24px;
      position: relative;
      z-index: 1;
      width: 100%;

      .Loancardbackground {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../Assets/loanSection-shape.png");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        animation: wave 4s linear infinite;
      }

      .loanCardCircle {
        margin-bottom: 20px;
        width: 55px;
        padding: 10px;
        height: 55px;
        border-radius: 50%;
        background-color: #fff;
      }

      h4 {
        font-size: 24px;
        font-weight: 700;
        font-family: $fontFamily2;
        color: #000;
        margin-bottom: 15px;
      }

      h6 {
        font-size: 18px;
        font-weight: 400;
        font-family: $fontFamily2;
        color: $Bluecolor2;
        margin-bottom: 30px;
      }
    }
  }

  .loanProduct-content {
    text-align: right;
  }
}

.offering-section {
  .offeringcard {
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0px 202.83px 250px 0px #7878a82b;
    padding-top: 50px;

    .imgs {
      position: relative;
      z-index: 1;
      height: 100%;

      img {
        position: absolute;
        border-bottom-left-radius: 40px;
      }
    }

    .offering-content {
      padding: 0px 20px;

      .contents {
        margin-top: 40px;
        padding-right: 40px;

        .boxes {
          margin-bottom: 40px;
          display: flex;
          align-items: center;

          .square {
            width: 80px;
            padding: 15px;
            height: 80px;
            border-radius: 20px;
            background-color: $purplecolor;
            margin-right: 30px;
            flex-shrink: 0;
          }

          .text {
            h5 {
              font-size: 20px;
              font-weight: 700;
              font-family: $fontFamily2;
              color: $Bluecolor3;
            }

            h6 {
              font-size: 18px;
              font-weight: 400;
              font-family: $fontFamily2;
              color: $greycolor;
            }
          }
        }
      }
    }
  }
}

.feature-section {
  .feature-outercard {
    padding: 50px 0px;

    .featurecard {
      display: flex;
      align-items: baseline;

      .text {
        h5 {
          font-size: 20px;
          font-weight: 700;
          color: $Bluecolor4;
          font-family: $fontFamily2;
        }

        h6 {
          font-size: 18px;
          font-weight: 400;
          color: $greycolor2;
          font-family: $fontFamily2;
          line-height: 30px;
        }
      }

      .featurecircle {
        flex-shrink: 0;
        padding: 1px 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $Bluecolor4;
      }
    }

    &.featuresecondcard {
      padding: 20px 10px;
      border-radius: 20px;
      margin-top: 20px;
      box-shadow: 8px 10px 30px 0px rgba(120, 120, 168, 0.168627451);
      transition: all 0.3s ease-in;

      &:hover {
        box-shadow: 8px 10px 2px -2px $Bluecolor5;
      }
    }
  }
}

.loanProduct-sectionSecond {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: $Bluecolor3;

  .loanProductShapeTop {
    position: absolute;
    right: 0;
    top: -1px;
    height: 100%;
    width: 100%;
    z-index: 0;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .text {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 18% 0% 10% 0%;

    .loanProductcardss {
      margin-top: 30px;
      background: #ffffff14;
      border: 1px solid #ffffff1f;
      border-radius: 16px;
      padding: 24px;

      .square {
        padding: 15px;
        width: 80px;
        height: 80px;
        border-radius: 20px;
        background-color: $color;
      }

      h5 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #fff;
        font-family: $fontFamily2;
        margin: 20px 0px 10px 0px;
      }

      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffffc7;
        font-family: $fontFamily2;
        margin: 10px 0px 30px 0px;
      }

      .loansectionbtn {
        border-radius: 7px;
        background: linear-gradient(96.71deg, #5656f2 0%, #3333dc 101.64%);
        box-shadow: 0px 4px 10px 0px #e7e7fb;
        padding: 6px 20px;
        text-decoration: none;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        font-family: $fontFamily2;
        transition: all 0.3s ease-in;

        &:hover {
          box-shadow: none;
          background: #fff;
          border: 1px solid #3333dc;
          color: $Bluecolor3;
        }
      }
    }
  }
}

.howItWorks-section {
  .howitworkscard {
    margin-top: 40px;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #e7e7e7 0%, #f3f3f3 100%);
    background-color: $color2;
    border-radius: 16px;
    padding: 30px 20px;
    transition: all 0.4s ease-in;

    .headingDiv {
      margin-top: 10px;
      display: flex;
      align-items: center;

      .circle {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: linear-gradient(
          161.83deg,
          #528df5 7.33%,
          #71a1f6 12.34%,
          #0257ee 90.74%,
          #0257ee 90.74%
        );
        padding: 5.5px 7px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        font-family: $fontFamily2;
        text-align: center;
      }

      h5 {
        font-size: 20px;
        font-weight: 400;
        font-family: $fontFamily2;
        margin-bottom: 0px;
      }
    }

    h6 {
      font-weight: 400;
      font-size: 16px;
      font-family: $fontFamily2;
      color: $greycolor3;
      margin: 20px 0px;
      line-height: 24px;
    }

    &:hover {
      box-shadow: 8px 10px 2px 0px $Bluecolor5;
    }
  }
}

.eligiblity-section {
  .elibilityTab {
    box-shadow: 0px 202.83px 250px 0px #7878a82b;
    background-color: #fff;
    border-radius: 40px;
    padding: 30px;

    .headss {
      h6 {
        margin-top: 20px;
        line-height: 28px;
        font-size: 18px;
        font-weight: 400;
        font-family: $fontFamily2;
        color: $greycolor;
      }
    }

    .Switches {
      margin: 30px 0px;
      display: flex;
      justify-content: center;

      .toptabs {
        display: flex;
        background-color: #f5f5f5;
        border-radius: 8px;
        padding: 0px;

        .tab {
          width: 160px;
          cursor: pointer;
          transition: all 0.1s ease-in;
          font-family: $fontFamily2;
          padding: 10px 20px;
          font-size: 18px;
          font-weight: 400;
          margin: 0px;
          text-align: center;
          border-radius: 8px;

          &.active {
            background-color: $Bluecolor3;
            color: #fff;
          }
        }
      }
    }

    .outerbox {
      position: relative;
      z-index: 1;
      margin-top: 30px;

      .headings {
        text-align: center;
        position: absolute;
        top: -22px;
        display: flex;
        width: 100%;
        justify-content: center;
        z-index: 1;

        .smallbox {
          border-radius: 20px;
          padding: 10px 30px;
          background-color: $Bluecolor3;
          display: inline-flex;

          h5 {
            margin-bottom: 0px;
            font-size: 22px;
            font-weight: 700;
            color: $color;
            text-align: center;
            display: inline;
            font-family: $fontFamily;
          }
        }
      }

      .innerbox {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 202.83px 490.55px 0px #7878a82b;
        padding: 40px 30px;
        border-top: 2px solid $Bluecolor3;

        .block {
          padding: 10px 30px;
          display: flex;
          align-items: baseline;

          .imgs {
            flex-shrink: 0;
          }

          .text {
            h5 {
              font-size: 20px;
              font-weight: 400;
              font-family: $fontFamily2;
              color: #000;
            }

            h6 {
              font-size: 18px;
              font-weight: 400;
              font-family: $fontFamily2;
              color: $greycolor;
            }
          }
        }
      }
    }

    .getStartedPart {
      background: linear-gradient(225deg, #707fe0 0%, #0d3a8f 100%);
      border-top: 1px solid;
      border-image-source: linear-gradient(231.64deg, #ec9acb 0%, #0d3a8f 100%);
      text-align: center;
      padding: 25px 12px;
      border-radius: 20px;

      h4 {
        font-weight: 400;
        line-height: 35px;
        font-size: 22px;
        color: $color;
        font-family: $fontFamily;
      }

      .btns {
        font-family: $fontFamily2;
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0px;
        padding: 8px 25px;
        border-radius: 7px;
        background-color: #fff;
        border: none;
        color: $Bluecolor3;
        transition: all 0.3s ease-in;

        &:hover {
          background-color: $Bluecolor3;
          box-shadow: 4px 4px 0px 0px #fff;
          color: #fff;
        }
      }
    }
  }
}

.EMICalculator {
  .headss {
    h6 {
      margin-top: 20px;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      font-family: $fontFamily2;
      color: $greycolor;
    }
  }

  .calcSection {
    box-shadow: 0px 202.83px 250px 0px #7878a82b;
    background-color: #fff;
    border-radius: 40px;
    padding: 30px;

    h3 {
      font-weight: 700;
      font-size: 28px;
      font-family: $fontFamily2;
      color: $Bluecolor6;
      margin-bottom: 15px;
    }

    .inputgrps {
      margin-top: 35px;

      .inputlabel {
        display: block;
        font-weight: 400;
        font-size: 18px;
        font-family: $fontFamily2;
        color: $Bluecolor6;
      }

      .inputTag {
        border-radius: 12px;
        width: 100%;
        border: none;
        margin: 10px 0px;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 700;
        background-color: $greycolor4;
        font-family: $fontFamily2;
      }

      .grpinput {
        border-radius: 12px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 700;
        background-color: $greycolor4;
        font-family: $fontFamily2;
        display: flex;

        .inputTag2 {
          font-size: 20px;
          font-weight: 700;
          padding: 3px 15px;
          border: none;
          width: 100%;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        .span {
          padding: 5px 10px;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          display: block;
          font-weight: 700;
          font-size: 20px;
          border-left: 2px solid #000;
        }
      }

      .sliders {
        .MuiSlider-root {
          .MuiSlider-rail {
            background-color: $orangecolor;
            opacity: 1;
          }

          .MuiSlider-track {
            background-color: $orangecolor;
            border: none;
          }

          .MuiSlider-thumb {
            background-color: $Bluecolor3;
          }

          .MuiSlider-mark {
            background-color: $orangecolor;
            top: 70%;
            width: 3.5px;
            height: 15px;
          }
        }
      }
    }

    .principalamount {
      text-align: center;
      margin: 30px 0px;

      h4 {
        background: linear-gradient(225deg, #707fe0 0%, #0d3a8f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 48px;
        font-weight: 700;
        font-family: $fontFamily2;
      }
    }

    .piechart {
      height: 200px;
    }

    .customLegends {
      margin: 20px 0px;
      display: flex;
      justify-content: center;

      .legends {
        display: flex;
        align-items: center;

        .square {
          width: 20px;
          height: 10px;
          border-radius: 5px;
          margin-right: 10px;
        }

        h6 {
          font-size: 16px;
          font-weight: 400;
          font-family: $fontFamily2;
          color: #000;
          margin: 0px;
        }
      }
    }

    .cardBox {
      background: linear-gradient(225deg, #707fe0 0%, #0d3a8f 100%);
      border-radius: 20px;
      padding: 20px 29px;
      margin: 25px 0px;

      .text {
        margin: 10px 0px;
        display: flex;
        justify-content: space-between;

        h5 {
          font-size: 13px;
          color: #fff;
          font-weight: 400;
          font-family: $fontFamily2;

          .bolds {
            font-weight: 700 !important;
          }
        }
      }
    }

    .applynow {
      text-align: center;

      .btn {
        background: linear-gradient(96.71deg, #5656f2 0%, #3333dc 101.64%);
        box-shadow: 0px 4px 10px 0px #e7e7fb;
        border-radius: 7px;
        font-size: 16px;
        padding: 5px 15px;
        font-weight: 700;
        color: #fff;
        border: none;
        transition: all 0.3s ease-in;

        &:hover {
          border: 1px solid $Bluecolor3;
          background: #fff;
          color: $Bluecolor3;
        }
      }
    }
  }
}

.lendingPartner-section {
  .partnerBox {
    position: relative;
    z-index: 1;
    border-radius: 40px;
    overflow: hidden;
    width: 100%;

    .partnerbg {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 40px;
      z-index: 0;
      background-image: url("../Assets/lendingPartnerbg.png");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .coinicon {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/coinicon.png");
      width: 70px;
      height: 70px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: -10px;
      left: 50%;
      transform: rotate(30deg) translateX(-50%);
    }

    .coinicon2 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/coinicon.png");
      width: 100px;
      height: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 10%;
      right: 0%;
      transform: rotate(30deg) translateX(40%);
    }

    .coinicon3 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/coinicon.png");
      width: 70px;
      height: 70px;
      background-size: 100%;
      background-repeat: no-repeat;
      bottom: 15%;
      left: 0%;
      transform: translateX(-30%);
    }

    .coinicon4 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/coinicon.png");
      width: 70px;
      height: 70px;
      background-size: 100%;
      background-repeat: no-repeat;
      bottom: 0%;
      left: 50%;
      transform: rotate(30deg) translateX(90%);
    }

    .noteicon {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/noteicon.png");
      width: 100px;
      height: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 15%;
      left: 0%;
      transform: translateX(50%);
    }

    .noteicon2 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/noteicon.png");
      width: 70px;
      height: 70px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 5%;
      right: 15%;
      transform: translateX(50%);
    }

    .text {
      padding: 5% 8%;
      width: 100%;
      position: relative;
      z-index: 2;

      h3 {
        text-align: center;
        margin: 0px 0px 50px 0px;
        font-size: 48px;
        font-weight: 700;
        font-family: $fontFamily;
        color: $Bluecolor3;
      }

      .leftText {
        h5 {
          font-size: 28px;
          font-weight: 700;
          line-height: 35px;
          font-family: $fontFamily;
          color: $Bluecolor3;
        }

        h6 {
          font-size: 22px;
          font-weight: 400;
          line-height: 35px;
          font-family: $fontFamily;
          color: $Bluecolor3;
          margin-bottom: 30px;
        }

        .linkbtn {
          background-color: #fff;
          border-radius: 12px;
          padding: 15px 25px;
          font-size: 20px;
          font-weight: 700;
          font-family: $fontFamily2;
          color: $Bluecolor3;
          text-decoration: none;
          transition: all 0.3s ease-in;

          &:hover {
            background-color: $Bluecolor3;
            color: #fff;
          }
        }
      }
    }
  }
}

.getStarted-section {
  .getStartedBox {
    position: relative;
    z-index: 2;
    border-radius: 40px;
    overflow: hidden;
    width: 100%;
    background-color: $Bluecolor3;

    .linesbg {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 40px;
      z-index: 0;
      background-image: url("../Assets/bglines.png");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      // animation: wave 4s linear infinite;
    }

    .mobilehand {
      position: absolute;
      bottom: -11px;
      right: 10%;
      z-index: 1;
      background-image: url("../Assets/mobilehandimg.png");
      width: 320px;
      height: 400px;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .circleimg {
      position: absolute;
      top: -10%;
      right: 4%;
      z-index: 0;
      background-image: url("../Assets/circleimg.png");
      width: 320px;
      height: 420px;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateX(20%);
    }

    .walletimg {
      position: absolute;
      top: 5%;
      right: 3%;
      z-index: 0;
      background-image: url("../Assets/walletimg.png");
      width: 170px;
      height: 170px;
      background-repeat: no-repeat;
      background-size: 100%;
      transform: translateX(0%);
    }

    .text {
      padding: 5% 8%;
      width: 100%;
      position: relative;
      z-index: 2;

      h3 {
        margin: 0px 0px 20px 0px;
        font-size: 48px;
        font-weight: 700;
        font-family: $fontFamily;
        color: #fff;
      }

      h5 {
        font-size: 22px;
        font-weight: 400;
        line-height: 35px;
        font-family: $fontFamily;
        color: $color;
      }

      .linkdiv {
        margin: 20px 0px;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px;
        display: flex;
        align-items: center;

        .square {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          padding: 7px;
          background-color: $greycolor5;
        }

        .linktext {
          display: flex;
          align-items: center;

          h5 {
            font-family: $fontFamily2;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color3;
            margin-bottom: 0px;
            margin-right: 10px;
          }

          a {
            font-family: $fontFamily2;
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.rating-section {
  .caraousel {
    .caraouselcards {
      .cards {
        margin: 15px;
        padding: 20px;
        box-shadow: 0px 0px 20px 0px #00000012;
        border-radius: 12px;
        background-color: #fff;

        .topsection {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .circle {
            width: 70px;
            height: 70px;
            background-color: #c4c4c4;
            border-radius: 50%;

            img {
              width: 70px;
              height: 70px;
              border-radius: 50%;
              object-fit: contain;
            }
          }
        }

        h5 {
          font-family: $fontFamily2;
          font-size: 18px;
          font-weight: 700;
          line-height: 20.7px;
          margin-bottom: 5px;
          color: #000;
        }

        .nameText {
          margin-left: 15px;

          h6 {
            color: #000;
            font-family: $fontFamily2;
            font-size: 12px;
            font-weight: 400;
            line-height: 13.8px;
            text-align: center;
          }
        }

        p {
          margin-top: 15px;
          font-family: $fontFamily2;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 15.8px;
        }
      }
    }
  }
}

.faqSection {
  padding: 5% 0% 5%;
  // padding: 5% 0% 10%;
  background-color: $color2;

  .headss {
    h6 {
      font-size: 16px;
      font-weight: 400;
      color: $greycolor3;
    }
  }

  .accordss {
    .accordion {
      .accordion-item {
        margin-top: 20px;
        border-radius: 16px;
        border: 1px solid $color2;

        .accordion-header {
          .accordion-button {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            font-size: 20px;
            font-family: $fontFamily2;
            font-weight: 400;
            color: #000;
            background-color: #fff;
            padding: 20px;
            box-shadow: none;

            &.collapsed {
              border-radius: 16px;
            }

            &:focus {
              box-shadow: none;
            }

            &::after {
              border-radius: 48px;
              padding: 15px;
              background-position: center;
              background-color: $lightBluecolor;
            }
          }
        }

        .accordion-collapse {
          font-size: 16px;
          font-weight: 400;
          color: $greycolor3;
          font-family: $fontFamily2;

          .accordion-body {
            padding-top: 0px;
          }
        }
      }
    }
  }
}

.customModal {
  .modal-body {
    background: linear-gradient(225deg, #707fe0 0%, #0d3a8f 100%);
    border-radius: 8px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: 100%;

    .linesbg {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 40px;
      z-index: 0;
      background-image: url("../Assets/bglines.png");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      // animation: wave 4s linear infinite;
    }

    .bolticon {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/bolticon.png");
      width: 100px;
      height: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 2%;
      left: 2%;
      transform: translateX(50%);
    }

    .bolticon2 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/bolticon.png");
      width: 80px;
      height: 80px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: 50%;
      left: -5%;
      transform: translateX(50%);
    }

    .bolticon3 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/bolticon.png");
      width: 80px;
      height: 80px;
      background-size: 100%;
      background-repeat: no-repeat;
      bottom: -10%;
      left: 35%;
      transform: translateX(50%);
    }

    .noteicon {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/noteicon.png");
      width: 100px;
      height: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      top: -2%;
      right: 5%;
      transform: translateX(50%);
    }

    .noteicon2 {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/noteicon.png");
      width: 100px;
      height: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      bottom: 15%;
      right: 12%;
      transform: translateX(50%);
    }

    .coinicon {
      position: absolute;
      z-index: 0;
      background-image: url("../Assets/coinicon2.png");
      width: 130px;
      height: 130px;
      background-size: 100%;
      background-repeat: no-repeat;
      bottom: -10%;
      left: -4%;
      transform: translateX(50%);
    }

    .texts {
      margin-bottom: 40px;
      position: relative;
      z-index: 2;

      .closeSection {
        display: flex;
        justify-content: end;
      }

      .centerText {
        h3 {
          text-align: center;
          color: #fff;
          font-family: $fontFamily;
          font-size: 48px;
          line-height: 68px;
          font-weight: bold;
          margin: 40px 0px 20px 0px;
        }

        h6 {
          margin: 20px 0px;
          text-align: center;
          color: $color;
          font-family: $fontFamily2;
          font-size: 22px;
          line-height: 35px;
          font-weight: 400;
        }

        .linkbtns {
          display: flex;
          justify-content: center;
          margin: 50px 0px;

          .links {
            cursor: pointer;

            a {
              position: relative;
              z-index: 2;
              display: block;
              height: 100%;
            }

            img {
              cursor: pointer;
            }

            &:hover {
              filter: drop-shadow(4px 4px 0px #fff);
            }
          }
        }
      }
    }
  }
}

.contactusPage {
  box-shadow: 0px 202.83px 490.55px 0px #7878a82b;
  border-top: 1px solid;
  // padding: 14% 0px;
  border-image-source: linear-gradient(231.64deg, #ec9acb 0%, #0d3a8f 100%);
  background: linear-gradient(225deg, #707fe0 0%, #0d3a8f 100%);

  .Lefttext {
    padding: 10px;
    h3 {
      font-family: $fontFamily2;
      font-size: 35px;
      font-weight: 700;
      line-height: 45.4px;
      color: #fff;
    }
    h5 {
      font-family: $fontFamily2;
      font-size: 20px;
      font-weight: 400;
      line-height: 25.94px;
      color: #fff;
    }
    .hrtag {
      width: 85px;
      height: 3px;
      border-radius: 10px;
      background: linear-gradient(90deg, #ff9b7f 0%, #ff6337 99.7%);
    }
    .aTag {
      text-decoration: none;
      display: block;

      font-family: Open Sans;
      font-size: 20px;
      font-weight: 600;
      line-height: 25.15px;
      text-align: left;
      color: #fff;
      span {
        opacity: 0.8;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .inputgrp {
      background-color: #fff;
      border: 1px solid #c8c8c8;
      border-radius: 6px;
      margin-top: 15px;
      width: 100%;
      .inputTag {
        border-radius: 6px;
        padding: 15px 20px;
        width: 100%;
        font-family: $fontFamily2;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.67px;
        color: #000;
        border: none;
        box-shadow: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.policy {
  margin-bottom: 300px;
  .policyBanner {
    background-image: url("../Assets/bannerShape.svg");
    position: relative;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .h1Tag {
      text-align: center;
      color: #fff;
      font-size: 50px;
    }
  }
  .text {
    margin: 3% 0px 5% 0px;
    .aTags {
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  p {
    font-family: $fontFamily2;
  }
  h1 {
    font-family: $fontFamily;
  }
}

@media screen and (max-width: 1200px) {
  .LoanProduct-section {
    #loanSection1 {
      order: 2;
    }

    #loanSection2 {
      order: 1;
    }

    .loanProduct-content {
      text-align: center;
    }
  }

  .offering-section {
    .imgs {
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .LoanProduct-section {
    .loanProduct-content {
      text-align: left;
    }
  }

  .lendingPartner-section {
    .partnerBox {
      .text {
        #lendingPartner1 {
          order: 2;
        }

        #lendingPartner2 {
          order: 1;
        }
      }
    }
  }

  .getStarted-section {
    .getStartedBox {
      .mobilehand {
        display: none;
      }

      .circleimg {
        opacity: 0.9;
      }

      .walletimg {
        right: 2%;
      }

      .text {
        .linkdiv {
          .linktext {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 30px;
  }

  .banner-section {
    .text {
      padding: 50% 0% 20% 0%;

      h1 {
        //  font-size: 30px;
        font-size: 45px;
      }
    }
  }

  .LoanProduct-section {
    .outerloancard {
      padding: 0px;

      .loanCard {
        padding: 24px;
      }
    }
  }

  .offering-section {
    .offering-content {
      padding: 0px;

      .contents {
        padding-right: 0px !important;

        .boxes {
          display: flex;
          flex-wrap: wrap;

          .text {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .feature-section {
    .imgs {
      img {
        width: 100%;
      }
    }
  }

  .eligiblity-section {
    .elibilityTab {
      padding: 30px 12px;

      .outerbox {
        .innerbox {
          padding: 50px 0px 20px 0px;

          .block {
            padding: 10px;
            display: flex;
            align-items: baseline;
          }
        }
      }
    }
  }

  .EMICalculator {
    .calcSection {
      padding: 30px 15px;
    }
  }

  .lendingPartner-section {
    .partnerBox {
      .text {
        padding: 5%;
      }
    }
  }

  .faqSection {
    .accordion-button {
      font-size: 16px !important;
    }

    .accordion-body {
      font-size: 14px;
    }
  }

  .customModal {
    .modal-body {
      .texts {
        .centerText {
          h3 {
            font-size: 35px;
            line-height: 40px;
          }

          h6 {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

// animation keyframes

@keyframes wave {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 50px 0;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.zoom-in {
  animation: zoomIn 0.5s forwards;
}

.zoom-out {
  animation: zoomOut 0.5s forwards;
}
